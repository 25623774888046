import lodashDebounce from 'lodash/debounce';
import { UAParser } from 'ua-parser-js';
import {
  format, addDays, startOfWeek, endOfWeek, startOfMonth, endOfMonth,
} from 'date-fns';
import { ReadonlyHeaders } from 'next/dist/server/web/spec-extension/adapters/headers';

export function isServer() {
  return typeof window === 'undefined';
}

export function isMobile(headers?: () => ReadonlyHeaders) {
  if (isServer()) {
    if (headers) {
      const userAgent = headers().get('user-agent');
      if (userAgent) {
        const parser = new UAParser();
        parser?.setUA(userAgent);
        const result = parser?.getResult();
        const isMobile = result?.device?.type === 'mobile' || result?.device?.type === 'tablet' || false;
        return isMobile;
      }
    }
    return false;
  }

  return window.matchMedia('(max-width: 640px)').matches;
}

export const debounce = lodashDebounce;

export function convertObjectToQueryParam(params: object) {
  return Object.keys(params).reduce((acc, cur, idx) => {
    const value = params[cur as keyof typeof params];
    if (value) {
      return `${acc}${idx === 0 ? '?' : '&'}${cur}=${value}`;
    }

    return acc;
  }, '');
}

export function removeEmptyParams(params: object) {
  return Object.keys(params).reduce((acc, cur) => {
    if (params[cur as keyof typeof params] !== '' && params[cur as keyof typeof params] !== 'undefined') {
      acc[cur as keyof typeof params] = params[cur as keyof typeof params];
    }
    return acc;
  }, {});
}

export function setItemOnStorage(key: string, value: string) {
  if (!isServer()) localStorage?.setItem(key, value);
}

export function getItemFromStorage(key: string) {
  if (isServer()) return null;
  return localStorage?.getItem(key);
}

export function updateUrlQueryParam({ param, value }: { param: string; value: string }) {
  if (!isServer()) {
    const url = new URL(window.location.href);
    url?.searchParams?.set(param, value);
    window?.history?.pushState({}, '', url);
  }
}

export function deleteUrlQueryParam({ param }: { param: string }) {
  if (!isServer()) {
    const url = new URL(window.location.href);
    if (url.searchParams.get(param)) {
      url.searchParams.delete(param);
      window?.history.pushState({}, '', url);
    }
  }
}

export const getDateSearch = (date: string) => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const daysUntilSaturday = (6 - dayOfWeek + 7) % 7;
  const daysUntilSunday = (7 - dayOfWeek) % 7;

  const allDates = {
    hoje: { from: today, to: '' },
    amanha: {
      from: addDays(today, 1),
      to: '',
    },
    'esta-semana': {
      from: startOfWeek(today),
      to: endOfWeek(today),
    },
    'este-fim-de-semana': {
      from: addDays(today, daysUntilSaturday === 0 ? 7 : daysUntilSaturday),
      to: addDays(today, daysUntilSunday === 0 ? 7 : daysUntilSunday),
    },
    'proxima-semana': {
      from: startOfWeek(addDays(today, 7)),
      to: endOfWeek(addDays(today, 7)),
    },
    'este-mes': {
      from: startOfMonth(today),
      to: endOfMonth(today),
    },
    'proximo-mes': {
      from: startOfMonth(addDays(today, 30)),
      to: endOfMonth(addDays(today, 30)),
    },
  };
  if (date && allDates[date as keyof typeof allDates]) {
    return `${format(allDates[date as keyof typeof allDates].from, 'yyyy-MM-dd')}${
      allDates[date as keyof typeof allDates].to ? `,${format(allDates[date as keyof typeof allDates].to, 'yyyy-MM-dd')}` : ''
    }`;
  }
  return '';
};

export function getMainUrl() {
  const urls: { [key: string]: string } = {
    stage: 'https://stage.sympla.com.br',
    homolog: 'https://homolog.sympla.com.br',
    prod: 'https://www.sympla.com.br',
  };

  return urls[process.env.NEXT_PUBLIC_CLOUD_FRONT_BUNDLE_ENVIRONMENT || 'stage'];
}

export function getMainUrlOrganizer() {
  const urls: { [key: string]: string } = {
    stage: 'https://organizador.svc-stage.sympla.com.br',
    homolog: 'https://organizador.svc-homolog.sympla.com.br',
    prod: 'https://organizador.sympla.com.br',
  };

  return urls[process.env.NEXT_PUBLIC_CLOUD_FRONT_BUNDLE_ENVIRONMENT || 'prod'];
}
