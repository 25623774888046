import { CARD_PREVIOUS } from 'constants/browserStorageNames';
import { getCookies } from 'cookies-next';
import { CardPreviousView } from 'matrix';
import { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies';

export function getCardPreviousView(cookieStore?: ReadonlyRequestCookies) {
  const cookieValue = cookieStore?.get(CARD_PREVIOUS)?.value || getCookies()[CARD_PREVIOUS];
  let cardPreviousView: CardPreviousView = { recents: [] };

  if (cookieValue) {
    try {
      cardPreviousView = JSON.parse(cookieStore ? cookieValue : decodeURIComponent(cookieValue)) as CardPreviousView;
    } catch {
      // Silently fail and keep cardPreviousViewCookie as default
    }
  }

  return cardPreviousView;
}
