import { getCookie } from 'cookies-next';
import { CardPreviousView } from 'matrix';
import { makeAutoObservable } from 'mobx';
import { getCardPreviousView } from 'utils/getCardPreviousView';

export type UserData = {
  token: string;
  refreshToken: string;
  expiration: number;
  refreshExpiration: number;
  endDate: string;
  endDateRefresh: string;
  dataUser: {
    email: string;
    firstName: string;
    lastName: string;
    fullName: string;
    suspended: string;
    id: number;
    phoneHome: string;
    isProducer: boolean;
    billing: {
      label: null | string;
      creditCardName: null | string;
      cpf: null | string;
      birthdate: null | string;
      phoneNum: null | string;
      addressZipCode: null | string;
      addressStreet: null | string;
      addressNum: null | string;
      addressStreet2: null | string;
      addressNeighborhood: null | string;
      addressCity: null | string;
      addressState: null | string;
    };
  };
}

export enum ModalType {
  SIGNIN,
  FORGOTPSW,
  SIGNUP,
}

export type AuthHydration = {
  cardPreviousView: CardPreviousView | null;
  userData: UserData | null;
};

export function saveSession(userData: UserData, shouldKeepSession: boolean) {
  if (shouldKeepSession) {
    localStorage.setItem('userData', JSON.stringify(userData));
    return;
  }
  sessionStorage.setItem('userData', JSON.stringify(userData));
}

function removeUserData() {
  localStorage.removeItem('userData');
  sessionStorage.removeItem('userData');
}

function getUserData() {
  const spbidwCookie = getCookie('_spbidw');

  if (!spbidwCookie || spbidwCookie === 'null') {
    removeUserData();
    return null;
  }

  let userData = localStorage.getItem('userData');

  if (!userData) {
    userData = sessionStorage.getItem('userData');
  }

  return userData;
}

export class AuthStore {
  isRecoveringSession: boolean;

  isHydrated: boolean;

  modalType: ModalType;

  isModalOpen: boolean;

  isModalAuthenticatedVisible: boolean;

  userData: UserData | null;

  cardPreviousView: CardPreviousView = { recents: [] };

  isAuthenticated = false;

  constructor() {
    this.isRecoveringSession = true;
    this.isHydrated = false;
    this.modalType = ModalType.SIGNIN;
    this.isModalOpen = false;
    this.isModalAuthenticatedVisible = false;
    this.userData = null;

    this.cardPreviousView = getCardPreviousView();

    makeAutoObservable(this);
  }

  setModalType = (modalType: ModalType) => {
    this.modalType = modalType;
  };

  setCardPreviousView = (cardPreviousView: CardPreviousView) => {
    this.cardPreviousView = cardPreviousView;
  };

  setModalOpen = (isModalOpen: boolean) => {
    this.isModalOpen = isModalOpen;
  };

  getIsModalOpen = () => this.isModalOpen;

  onCloseModal = () => {
    this.setModalOpen(false);
  };

  getIsModalAuthenticatedVisible = () => this.isModalAuthenticatedVisible;

  setModalAuthenticatedVisible = (isOpen: boolean) => {
    this.isModalAuthenticatedVisible = isOpen;
  };

  openForgotPswModal = () => {
    this.setModalType(ModalType.FORGOTPSW);
    this.setModalOpen(true);
  };

  openSignInModal = () => {
    this.setModalType(ModalType.SIGNIN);
    this.setModalOpen(true);
  };

  openSignUpModal = () => {
    this.setModalType(ModalType.SIGNUP);
    this.setModalOpen(true);
  };

  updateUserData = async (userData: UserData, shouldKeepSession: boolean) => {
    this.userData = userData;
    this.isAuthenticated = true;
    saveSession(userData, shouldKeepSession);
  };

  // get isAuthenticated() {
  //   return this.userData !== null;
  // }

  setUserData = (userData: UserData | null) => {
    this.userData = userData;
  };

  getSession = () => {
    const userData = getUserData();
    if (userData) {
      this.isAuthenticated = true;
      this.setUserData(JSON.parse(userData));
    }
  };

  setIsRecoveringSession = (isRecoveringSession: boolean) => {
    this.isRecoveringSession = isRecoveringSession;
  };

  logOut = () => {
    removeUserData();
    this.setUserData(null);
    this.isAuthenticated = false;
  };

  hydrate = (data: AuthHydration) => {
    if (this.isHydrated) return;

    this.cardPreviousView = data?.cardPreviousView || { recents: [] };

    this.userData = data?.userData || null;

    this.isHydrated = true;
  };
}

const authStore = new AuthStore();
export default authStore;
