import { makeAutoObservable } from 'mobx';
import { ANYWHERE_LOCATION_JSON } from 'constants/index';
import { getItemFromStorage, setItemOnStorage } from 'utils/misc';
import { CITIES } from 'constants/browserStorageNames';
import { Location } from 'location.d';
import getCityClient from 'services/getCity/getCityClient';
import { setLocationCookies } from 'components/Header/components/utils/setLocationCookies';
import { statesList, urlPathname } from 'constants/search';

export type LocationHydration = {
  selectedLocation: Location;
  cities: Location[];
};

export class LocationStore {
  isHydrated: boolean;

  selectedLocation: Location;

  isFetchingCities: boolean;

  cities: Location[];

  isGeolocationVisible: boolean;

  isGeolocationModalVisible: boolean;

  isLocationModalVisible: boolean;

  constructor() {
    this.selectedLocation = ANYWHERE_LOCATION_JSON;
    this.isFetchingCities = false;
    this.cities = [];
    this.isGeolocationVisible = false;
    this.isGeolocationModalVisible = false;
    this.isLocationModalVisible = false;
    this.isHydrated = false;

    makeAutoObservable(this);
  }

  setSelectedLocation = (location: Location) => {
    this.selectedLocation = location;
  };

  setIsFetchingCities = (state: boolean) => {
    this.isFetchingCities = state;
  };

  fillCities = async (name?: string) => {
    const payloadFromStorage = getItemFromStorage(CITIES);
    if (!name && payloadFromStorage) {
      this.cities = JSON.parse(payloadFromStorage);
      return;
    }

    try {
      this.setIsFetchingCities(true);
      const response = await getCityClient(name);
      if (name) {
        this.cities = response;
        this.setIsFetchingCities(false);
        return;
      }

      const cities = [ANYWHERE_LOCATION_JSON, ...response];
      setItemOnStorage(CITIES, JSON.stringify(cities));
      this.setIsFetchingCities(false);

      this.cities = cities;
    } catch (error) {
      this.cities = [ANYWHERE_LOCATION_JSON];
      this.setIsFetchingCities(false);
    }
  };

  setIsGeolocationVisible = (status: boolean) => {
    this.isGeolocationVisible = status;
  };

  setIsGeolocationModalVisible = (status: boolean) => {
    this.isGeolocationModalVisible = status;
  };

  setIsLocationModalVisible = (status: boolean) => {
    this.isLocationModalVisible = status;
  };

  hydrateSelectedLocation = async (data: Partial<LocationHydration>) => {
    const isClient = typeof window !== 'undefined';
    if (isClient) {
      const url = new URL(window.location.href);
      const pathnameSegments = url.pathname.split('/');
      const currentPage = pathnameSegments[1];
      const cityURL = pathnameSegments[2]?.split('?')[0];

      const isCity = statesList.includes(cityURL?.slice(-3));

      if (currentPage === urlPathname.events && cityURL && cityURL !== data?.selectedLocation?.slug && isCity) {
        const nameCity = cityURL?.replace(/-/g, ' ')?.slice(0, -3);
        await this.fillCities(nameCity);
        const selectedCity = this.cities.find(({ slug }) => cityURL === slug);
        if (selectedCity) {
          this.selectedLocation = selectedCity;
          setLocationCookies({ userLocation: selectedCity });
        }
      } else if (data.selectedLocation) this.selectedLocation = data.selectedLocation;
    }
  };

  hydrate = (data: Partial<LocationHydration>) => {
    if (this.isHydrated) return;

    if (data.cities) {
      this.cities = data.cities;
    }

    this.hydrateSelectedLocation(data);
    this.isHydrated = true;
  };
}

const locationStore = new LocationStore();
export default locationStore;
