export const GEOLOCATION_NOTIFICATION = 's_geo_location_notification';

export const GEOLOCATION_PERMISSION = 's_user_geo_location';

export const CANT_CACHE_LOCATION = 's_cant_cache_location';

export const USER_LOCATION = 's_user_location';

export const USER_ID = '_spbidw';

export const CITIES = 's_cities';

export const LANGS = 's_cities';

export const CARD_PREVIOUS = 'sympla_rv';
