export const tabsSearch = {
  event: {
    label: 'Eventos',
    tabName: 'eventos',
    ariaLabel: 'Eventos',
  },
  place: {
    label: 'Locais',
    tabName: 'locais',
    ariaLabel: 'Locais',
  },
  organizer: {
    label: 'Produtores',
    tabName: 'produtores',
    ariaLabel: 'Produtores',
  },
};

export const paramsPageSearch = {
  search: 'search',
  matrix: 'matrix',
};

export const urlParamsSearch = {
  page: 'page',
  date: 'd',
  term: 's',
  tab: 'tab',
  location: 'l',
  ignoreLocation: 'ignoreLocation',
  category: 'cl',
};

export const urlPathname = {
  events: 'eventos',
  collection: 'colecao',
};

export const componentTrackingSearch = {
  category: 'discoveryInteraction',
  termNoResultOtherCities: 'by_term-outras_cidades',
  termOtherCities: 'by_term_result-outras_cidades',
  term: 'by_term-full',
  searchOtherResult: 'searchOtherResult',
  componentButtonShowMore: 'Component-Botão_MostrarMaisEventos',
  actionClick: 'click',
  sectionMoreEventsCollection: 'explore-mais-eventlist',
};

export const initialSearchDataResult = {
  data: [],
  limit: 0,
  page: 0,
  total: 0,
};

export const templateSearch = {
  city: 'city',
  collectionMatrix: 'collection-matrix',
};

export const statesList = Object.freeze([
  '-ac',
  '-al',
  '-am',
  '-ap',
  '-ba',
  '-ce',
  '-df',
  '-es',
  '-go',
  '-ma',
  '-mt',
  '-ms',
  '-mg',
  '-pr',
  '-pb',
  '-pe',
  '-pi',
  '-rj',
  '-rn',
  '-rs',
  '-ro',
  '-rr',
  '-sc',
  '-se',
  '-sp',
  '-to',
]);
