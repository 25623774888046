import axios, { AxiosError } from 'axios';
import { saveSession } from 'stores/AuthStore';
import { isServer } from 'utils/misc';

function createBrowserBaseUrl() {
  if (isServer()) return undefined;
  if (process.env.NEXT_PUBLIC_CLOUD_FRONT_BUNDLE_ENVIRONMENT) return process.env.NEXT_PUBLIC_BASE_URL_BFF;
  return `${window.location.origin}/api/discovery-bff`;
}

export const serverApi = axios.create({
  baseURL: process.env.BASE_API_URL,
  headers: {
    Authorization: `Bearer ${process.env.API_KEY}`,
  },
});

export const browserApi = axios.create({
  baseURL: createBrowserBaseUrl(),
  timeout: 90000,
});

browserApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const userDataString = localStorage.getItem('userData') || sessionStorage.getItem('userData');
      const userData = userDataString ? JSON.parse(userDataString) : {};
      if (userData && userData.token && userData.refreshToken) {
        const { token, refreshToken } = userData;
        const newUserData = await window.symplaAuthComponent.refreshToken(token, refreshToken);
        if (newUserData && newUserData?.token) {
          const checkSession = document.querySelector('auth-component #keepMeConnected') as HTMLInputElement;
          const shouldKeepSession = checkSession?.checked;
          saveSession(newUserData, shouldKeepSession);
          return browserApi.request(error.config);
        }
      }
    }
    return Promise.reject(error);
  },
);

export type HttpClientError = AxiosError
