import { browserApi } from 'lib/httpClient';
import { Location } from 'location.d';

async function getCityClient(name?: string): Promise<Location[]> {
  const { data } = await browserApi.get<Location[]>('/city', {
    params: { name },
  });
  return data;
}

export default getCityClient;
