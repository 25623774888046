/* eslint-disable max-len */

export const ANYWHERE_LOCATION_JSON = { city: 'Qualquer lugar', coordinates: { lat: '', lon: '' }, slug: '' };

export const ANYWHERE_LOCATION = 'Qualquer lugar';

export const NEAR_TO_YOU_LABEL = 'Perto de você';

export const DEFAULT_IMAGE = 'https://d1gkntzr8mxq7s.cloudfront.net/65df83b3c4d68.svg';

export const DEFAULT_IMAGE_BASE64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAQAAAD8IX00AAAAEElEQVR42mP8ZswABIwoFAAs5QN/jiaZIgAAAABJRU5ErkJggg==';

export const PAGES_URL = {
  events: '/eventos',
  favorite: '/favoritos',
  helpCenter: 'https://ajuda.sympla.com.br/hc/pt-br/',
  home: '/',
  login: '/login',
  myAccount: '/minha-conta',
  myTickets: '/meus-pedidos',
  organizer: 'https://organizador.sympla.com.br/',
  organizerArea: '/meus-eventos',
  organizerCreateEvent: '/criar-evento?presencial=1',
};

export const MATRIX_TYPE = {
  TOPIC: 'topic',
  HOME_MATRIX: 'home-matrix',
  MATRIX: 'matriz',
  HOME: 'home',
};

export const CLASS_TRACKING_GTM = {
  COLLECTION_CARD: 'sympla-category-card',
  EVENT_CARD: 'sympla-card',
};

export const ROUTE_TYPE = {
  SEARCH_BAR_DEFAULT_FILTER: 'search-bar-default-filter',
};

export const LIMITS = {
  EVENTS: 24,
  RESULT_EVENT: 12,
  EVENTS_OTHER_CITIES: 8,
  PLACES: 15,
  TRACK_MORE_EVENTS: 16,
};
